#home-img {
  height: 144px;
  width: 144px;
  background-color: white;
  border-radius: initial;
}

.home-page {
  width: 27em !important;
  min-width: 27em;
  padding: 2.5em 3em 0.5em 3em;
}