/* Nav */

#nav {
  margin: 30px 0 auto 0;
  display: block;
  text-align: center;
  z-index: 3;
  width: 100vw;
}

#nav > ul {
  display: inline-block;
  border-radius: 0.35em;
  box-shadow: inset 0px 0px 1px 1px rgba(255, 255, 255, 0.25);
  padding: 0 1.5em 0 1.5em;
}

#nav > ul > li {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  padding: 0 1.5em 0 1.5em;
}

#nav > ul > li > ul {
  display: none;
}

#nav > ul > li > p,
#nav > ul > li > span {
  cursor: pointer;
  margin: 0;
  display: block;
  color: #eee;
  color: rgba(255, 255, 255, 0.75);
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.7em;
  letter-spacing: 0.25em;
  height: 5em;
  line-height: 5em;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  outline: 0;
}

#nav > ul > li:hover > p {
  color: #fff;
}

#nav > ul > li.active > p,
#nav > ul > li.active > span {
  color: #fff;
}

.dropotron {
  background: #222835 url('../../images/overlay.png');
  background-color: rgba(44, 50, 63, 0.925);
  padding: 1.25em 1em 0.9em 1em;
  border-radius: 0.35em;
  box-shadow: inset 0px 0px 1px 1px rgba(255, 255, 255, 0.25);
  min-width: 12em;
  text-align: left;
  margin-top: -1.25em;
  margin-left: -1px;
}

.dropotron.level-0 {
  margin-top: -1px;
  margin-left: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.dropotron p,
.dropotron span {
  margin: 0;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  display: block;
  color: #eee;
  color: rgba(255, 255, 255, 0.75);
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.7em;
  letter-spacing: 0.25em;
  border-top: solid 1px rgba(255, 255, 255, 0.15);
  line-height: 3em;
}

.dropotron li:first-child p,
.dropotron li:first-child span {
  border-top: 0;
}

.dropotron li:hover > p,
.dropotron li:hover > span {
  color: #fff;
}

#wrapper > div {
  width: 100%;
  margin: 0 0 auto 0;
}

.bm-burger-button {
  margin-top: 2em;
  margin-bottom: 1em;
  position: relative;
  width: 2.7em;
  height: 2.2em;
  left: 2.7em;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
  border-radius: 10px;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-menu-wrap {
  bottom: 0;
  left: 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

.bm-item p {
  cursor: pointer;
}

.bm-overlay {
  width: unset !important;
  height: unset !important;
}
