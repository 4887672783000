.resume-page {
  padding: 2.5em 3em 2.5em 3em;
  min-width: calc(80vw);
  max-width: 80vw;
  min-height: 400px;
  height: 100vh;
  max-height: 80vh;
  overflow: auto;
}

.resume-pdf {
  width: 100%;
  min-height: 100%;
}
