#about-img {
  height: 200px;
  width: 200px;
  border-radius: 100%;
}

.about-page {
  max-width: 50%;
  padding: 2.5em 3em 0.5em 3em;
}

.about-page > p {
  text-align: left;
  text-transform: none;
  color: black;
  letter-spacing: 0.075em;
}

@media screen and (max-width: 600px) {
  .about-page {
    max-width: 100%;
  }

  .about-page > p {
    font-size: 0.85em;
  }
}